* {
    padding: 0;
    margin: 0;
    border: 0px;

    font-family: 'Pretendard' !important;
    transition-duration: 0.3s;
}

video::-webkit-media-controls {
    display: none !important;
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

.ant-layout-header {
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    padding: 0 55px;
    position: fixed;
    z-index: 100;
    background-color: transparent;

    .ant-layout-content {
        height: 100%;
        max-width: 1600px;
        margin: 0 auto;
    }

    &.ant-layout-sticky-header {
        background-color: #000000;
        border-bottom: 1px solid #F8B31A;

        .navigation {
            a {
                color: #ffffff;

                &.activate {
                    &:hover {
                        color: #000;
                    }
                }

                &:hover {
                    color: #F8B31A;
                }
            }
        }
    }

    .navigation {
        a {
            color: #ffffff;

            &.activate {
                color: #F8B31A;

                &:hover {
                    color: #000;
                }
            }

            &:hover {
                color: #F8B31A;
            }

            h2 {
                margin: 0px;
            }
        }
    }
}

.ant-divider {
    margin: 0px;
}

.home {
    .home-header {
        .typhography {
            max-width: 1600px;
            margin: 0px auto;
            position: relative;

            .typhography-wrapper {
                position: absolute;
                z-index: 10;

                .title {
                    line-height: 50px;

                    span {
                        font-size: 48px;
                        font-weight: 500;
                    }
                }

                .subtitle {
                    span {
                        font-size: 1.2em;
                    }
                }
            }
        }
    }

    .home-service {
        padding: 120px 55px;
        margin: 0px auto;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .item-container {
                padding: 0px 6px;
                border-radius: 6px;

                &:hover {
                    border: 2px solid #F8B31A;
                }

                .item {
                    min-height: 320px;
                    padding: 12px;

                    .content {
                        font-size: 15px;
                        line-height: 24px;
                        font-weight: 300;
                        color: #41525e;
                    }
                }
            }
        }

        .left-arrow,
        .right-arrow {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid #fff;
            padding: 5px 0;
            cursor: pointer;

            &:hover {
                background-color: #363a3d;
                border: 1px solid #363a3d;
            }
        }
    }

    .home-organization {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .item-container {
                padding: 0px 6px;
                border-radius: 6px;
                overflow: hidden;

                .item {
                    padding: 12px;
                    min-height: 310px;

                    .ant-col {
                        z-index: 10;
                    }

                    .title {
                        white-space: pre-wrap;
                        font-weight: 700;
                        font-size: 36px;
                    }

                    .content {
                        white-space: pre-wrap;
                        font-size: 15px;
                        line-height: 24px;
                        font-weight: 500;
                        color: #41525e;
                    }

                    ul {
                        list-style-type: disc;

                        li {
                            color: #4f4f4f;
                        }
                    }
                }

                img {
                    height: 100%;
                }

                &.no-background {
                    background-color: #000;

                    .title {
                        font-weight: 700;
                        color: #F8B31A;
                    }

                    .content {
                        color: #fff;
                    }
                }

                &:hover {
                    transition-duration: 0.5s;
                    border: 2px solid #F8B31A;
                    background-color: #000;

                    &.no-background {
                        background-color: #fff;

                        .title {
                            color: #000;
                        }

                        .content {
                            color: #000;
                        }
                    }

                    .title,
                    .subtitle {
                        color: #F8B31A;
                    }

                    .content {
                        color: #fff;
                    }

                    ul {
                        li {
                            color: #d3d3d3;
                        }
                    }

                    img {
                        display: none;
                    }
                }
            }
        }
    }

    .home-partner {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1200px;
            margin: 0px auto;

            .item-container {
                padding: 0px 6px;
                border-radius: 6px;
                overflow: hidden;
                min-height: 140px;
                align-content: center;

                img {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                &:hover {
                    border: 3px solid #F8B31A;

                    img {
                        transform: scale(1.5);
                    }
                }
            }
        }
    }

    .home-location-and-inquiry {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .location {
                padding-right: 24px;

                .map {
                    margin-top: 24px;
                    transition-duration: 0s;
                    width: 100%;
                    height: 450px;
                    padding-right: 32px;
                }

                .info {
                    margin-top: 18px;

                    a,
                    span {
                        font-size: 18px;
                        color: #c8c8c8;

                        &:hover {
                            color: #F8B31A;
                        }
                    }
                }
            }

            .contact {
                padding-left: 36px;

                .inquiry {
                    padding: 34px 18px;
                    background-color: #000000;
                    border-radius: 8px;
                    border: 2px solid #9d9d9d;

                    label {
                        font-weight: 700;
                        color: #c6c6c6;
                    }

                    span {
                        color: #e5e5e5;
                    }

                    button {
                        span {
                            color: #000;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .divider {
            display: none;
        }
    }
}

.blog {
    .header {
        .parallax {
            // background-image: url("https://woowahan-cdn.woowahan.com/static/image/together/pc/visual@3x.jpg?59bd60cb");
            background-attachment: initial;
            // min-height: 500px;
            // background-position: center center;
            background-position: left;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
        }

        button {
            padding: 7px 22px;

            span {
                color: #F8B31A;
            }

            &:hover {
                border-color: #fff !important;

                span {
                    color: #fff;
                }
            }
        }
    }

    .menu {
        padding: 20px;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            color: #232324 !important;
        }
    }

    .items {
        cursor: pointer;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.who-we-are {
    .who-we-are-header {
        .parallax {
            background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/assets/background/couple.jpg");
            background-attachment: initial;
            // min-height: 500px;
            // background-position: center center;
            background-position: left;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
        }

        .pc-content {
            display: block;
        }
        .mobile-content {
            display: none;
        }

        button {
            padding: 7px 22px;

            span {
                color: #F8B31A;
            }

            &:hover {
                border-color: #fff !important;

                span {
                    color: #fff;
                }
            }
        }
    }

    .grow-up {
        margin: 0px auto;
        padding: 150px 16px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .item-container {
                padding: 0px 6px;
                border-radius: 6px;
                overflow: hidden;
                min-height: 324px;

                .item {
                    min-height: 260px;

                    .ant-col {
                        z-index: 10;
                    }

                    .title {
                        .ant-typography {
                            font-size: 52px;
                            font-weight: 700;
                            color: #F8B31A;
                        }
                    }

                    .subtitle {
                        .ant-typography {
                            font-size: 32px;
                            font-weight: 700;
                        }
                    }

                    .content {
                        .ant-typography {
                            font-size: 15px;
                            line-height: 24px;
                            font-weight: 300;
                            opacity: 0;
                            color: #fff;
                        }
                    }
                }

                &:hover {
                    transition-duration: 0.5s;
                    border: 2px solid #F8B31A;
                    background-color: #000;

                    .title {
                        .ant-typography {
                            color: #fff;
                            font-size: 36px;
                        }
                    }

                    .subtitle {
                        .ant-typography {
                            color: #F8B31A;
                        }
                    }

                    .content {
                        .ant-typography {
                            color: #fff;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .solution {
        margin: 0px auto;
        padding: 150px 55px;
        background-color: #000;
        position: relative;

        &.background {
            background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage/many_wolfs.jpg");
            background-attachment: initial;
            background-position: left;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
        }


        .item-container {
            padding: 0px 6px;
            border-radius: 6px;
            overflow: hidden;
            width: 100%;
            position: relative;

            .ant-card {
                min-height: 260px;

                .ant-card-body {
                    min-height: 260px;
                    justify-items: center;
                    align-content: center;

                    .ant-typography {
                        position: relative;
                        font-size: 24px;
                        font-weight: 700;
                        color: #000000;
                        z-index: 2;
                        margin-bottom: 0px;
                    }
        
                    img {
                        position: absolute;
                        top: 0px;
                        bottom: 0px;
                        left: 0px;
                        right: 0px;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        opacity: 1;
                    }
        
                    &:hover {
                        .ant-typography {
                            color: #F8B31A;
                            font-size: 28px;
                        }
        
                        img {
                            opacity: 0.5 !important;
                        }
                    }
                }
            }
        }
    }

    .core-value {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .item-container {
                padding: 0px 6px;
                border-radius: 6px;
                overflow: hidden;
                min-height: 324px;

                .item {
                    min-height: 260px;

                    .ant-col {
                        z-index: 10;
                    }

                    .title {
                        .ant-typography {
                            font-size: 52px;
                            font-weight: 700;
                            color: #F8B31A;
                        }
                    }

                    .subtitle {
                        .ant-typography {
                            font-size: 32px;
                            font-weight: 700;
                        }
                    }

                    .content {
                        .ant-typography {
                            font-size: 15px;
                            line-height: 24px;
                            font-weight: 300;
                            opacity: 0;
                            color: #fff;
                        }
                    }
                }

                &:hover {
                    transition-duration: 0.5s;
                    border: 2px solid #F8B31A;
                    background-color: #000;

                    .title {
                        .ant-typography {
                            color: #fff;
                            font-size: 36px;
                        }
                    }

                    .subtitle {
                        .ant-typography {
                            color: #F8B31A;
                        }
                    }

                    .content {
                        .ant-typography {
                            color: #fff;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .history {
        margin: 0px auto;
        padding: 100px 55px;
        background-color: #000;

        .ant-timeline-item-tail {
            border-inline-start: 2px solid #F8B31A;
        }

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .item {
                width: fit-content;
                padding: 24px;
                border-radius: 8px;

                &.rtl {
                    text-align: right;

                    ul {
                        direction: rtl;

                        li {
                            list-style-type: disc;
                        }
                    }
                }

                .ant-card-body {

                    .ant-typography,
                    ul {
                        margin-bottom: 0px;
                    }

                }
            }
        }
    }
}

.works {
    .header {
        .parallax {
            background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage/sunset.jpg");
            // background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/assets/background/wolfs.jpg");
            background-attachment: initial;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .reference {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;

            .item-container {
                padding: 0px 6px;
                border-radius: 6px;
                overflow: hidden;
                min-height: 324px;
                background-color: #000;

                .item {
                    min-height: 260px;

                    .ant-col {
                        z-index: 10;
                    }

                    .title {
                        .ant-typography {
                            font-size: 24px;
                            font-weight: 700;
                            color: #fff;
                        }
                    }

                    .content {
                        .ant-typography {
                            font-size: 15px;
                            line-height: 24px;
                            font-weight: 300;
                            color: #fff;
                        }
                    }
                }

                img {
                    opacity: 0.7;
                }

                &:hover {
                    transition-duration: 0.5s;
                    border: 3px solid #F8B31A;

                    .title {
                        .ant-typography {
                            color: #F8B31A;
                        }
                    }

                    img {
                        opacity: 0.3;
                        filter: blur(0.15em);
                    }
                }
            }
        }

        .ant-pagination-item:not(.ant-pagination-item-active) {
            &:hover {
                a {
                    color: #fff;
                    border: 1px solid #F8B31A;
                    border-radius: 4px;
                }
            }
        }
    }
}

.wanted {
    .header {
        .parallax {
            background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage/sunset.jpg");
            background-attachment: initial;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .position {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        .ant-layout-content {
            max-width: 1600px;
            margin: 0px auto;
        }

        .item {
            width: 100%;

            &:hover {
                background-color: #000;
                border: 1px solid #F8B31A;
                border-radius: 4px;

                .title,
                .role {
                    color: #ccc;
                }

                .divide {
                    .ant-space-item-split {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.contact {
    .header {
        .parallax {
            background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/assets/background/wolf.jpg");
            background-attachment: initial;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .inquire {
        margin: 0px auto;
        padding: 120px 55px;
        background-color: #000;

        label {
            font-weight: 700;
            color: #c6c6c6;
        }

        span {
            color: #e5e5e5;
        }

        button {
            span {
                color: #000;
                font-weight: 700;
            }
        }

        .ant-layout-content {
            max-width: 900px;
            margin: 0px auto;

            .title {
                .ant-typography {
                    font-size: 32px;
                }
            }
        }
    }
}

.login {
    .header {
        .parallax {
            background-image: url("https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/assets/background/white-wolf.jpg");
            background-attachment: initial;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .body {
        margin: 20px auto;
        padding: 55px;

        .ant-layout-content {
            max-width: 900px;
            margin: 0px auto;

            .title {
                .ant-typography {
                    font-size: 32px;
                }
            }
        }
    }
}

.footer {
    background-color: #000;
    padding: 55px 55px;
    border-top: 1px solid #F8B31A;

    .sns {
        .ant-space-item {
            width: 50px;
            height: 50px;
            background-color: #fff;
            border-radius: 50px;

            &:hover {
                background-color: #000;
                border: 2px solid #F8B31A;
            }
        }
    }

    a {
        &:hover {

            a,
            span,
            h5 {
                color: #F8B31A;
            }
        }
    }

    a,
    span,
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 0px;
        color: #999;
    }

    button {
        padding: 7px 22px;

        span {
            color: #F8B31A;
        }

        &:hover {
            border-color: #fff !important;

            span {
                color: #fff;
            }
        }
    }
}

.ant-modal {
    &.reference {
        .img {
            position: relative;
            min-height: 260px;

            .ant-flex {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 4px;
                }
            }

            .description {
                top: 0px;
                left: 0%;
                bottom: 0;
                width: 100%;
                padding: 16px;
                position: absolute;
                border-radius: 4px;
                z-index: 5;
                opacity: 0;

                .ant-typography {
                    color: #f0f2f5;
                    font-size: 16px;
                    white-space: pre-wrap;
                }
            }

            .cover {
                top: 0px;
                left: 0%;
                bottom: 0;
                width: 100%;
                padding: 16px;
                position: absolute;
                border-radius: 4px;
                background-color: #000;
                z-index: 1;
                opacity: 0;
            }

            &:hover {
                .description {
                    opacity: 1;
                }

                .cover {
                    opacity: 0.6;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .ant-layout-header {
        padding: 0px;

        .top {
            padding: 0 16px;
        }

        &.ant-layout-sticky-header {

            .navigation {
                a {
                    color: #000;
                }
            }
        }

        .navigation {
            margin-top: 36px;

            a {
                color: #000;

                h2 {
                    margin: 0px;
                }
            }
        }
    }

    .home {
        .home-service,
        .home-organization,
        .home-partner,
        .home-location-and-inquiry {
            padding: 60px 12px;
        }

        .home-location-and-inquiry {
            .ant-layout-content {
                padding: 0px 6px;

                .location {
                    padding: 0px;

                    .map {
                        height: 300px
                    }
                }

                .contact {
                    padding: 0px;

                    .ant-form {
                        padding: 0px 6px;
                    }
                }
            }

            button {
                width: 100%;
                margin: 0px 10px;
            }

            .divider {
                display: block;
            }
        }
    }

    .who-we-are {
        .who-we-are-header {
            .pc-content {
                display: none;
            }
            .mobile-content {
                display: block;
                font-size: 14px;
            }
        }

        .core-value {
            margin: 0px auto;
            padding: 60px 12px;
        }

        .history {
            margin: 0;
            padding: 12px;
        }
    }

    .works {
        .reference {
            padding: 12px;
        }
    }

    .wanted {
        .position {
            padding: 16px 12px 66px;

            .ant-card-body {
                padding: 12px 12px;
            }
        }
    }

    .contact {
        .inquire {
            padding: 24px 12px 72px;

            .ant-layout-content {
                .title {
                    .ant-typography {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .footer {
        padding: 32px 16px;

        .sns {
            .ant-space-item {
                width: 38px;
                height: 38px;
                background-color: #fff;
                border-radius: 35px;
            }
        }
    }
}

.ql-editor {
    font-size: 16px;

    p,
    span,
    em,
    blockquote {
        font-size: 16px;
    }

    p {
        min-height: 28px;
        line-height: 28px;
    }

    img {
        width: 100%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
    .ant-layout-header {
        .navigation {
            a {
                color: #000;
            }
        }
    }

    .home {
        .home-header {
            .typhography {
                .typhography-wrapper {
                    .title {
                        span {
                            font-size: 40px !important;
                            font-weight: 500;
                        }
                    }

                    .subtitle {
                        text-align: center;

                        span {
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }

        .home-location-and-inquiry {
            .ant-layout-content {
                padding: 0px 6px;

                .location {
                    padding: 0px;
                }

                .contact {
                    padding: 0px;

                    .ant-form {
                        padding: 0px 6px;
                    }
                }
            }

            .divider {
                display: block;
            }
        }
    }
}